import React, {useEffect, useRef, useState} from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for selectable
import zhLocale from '@fullcalendar/core/locales/zh-cn';
import {Toast} from 'antd-mobile'
import './Calendar.css';
import {API} from "../const";

function getQueryParam(param: string) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}


const Calendar = () => {
    const [events, setEvents] = useState([]);

    const queryType = getQueryParam('t');
    const queryID = getQueryParam('i')

    useEffect(() => {
        fetch(API.user_calendar + `?t=${queryType}&i=${queryID}`)
            .then(response => response.json())
            .then(data => {
                if (data.code === 200) {
                    const tasks = data.data.map((task: { name: any; end_date_planned: any }) => ({
                        title: task.name,
                        start: task.end_date_planned,
                        end: task.end_date_planned,
                        allDay: true
                    }));
                    setEvents(tasks);
                } else {
                    Toast.show({
                        content: '数据加载错误',
                        duration: 3000,
                        position: 'center',
                    })
                }
            })
            .catch(error => console.error('Error fetching tasks:', error));
    }, []);

    return (
        <div className="calendar-container">
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'timeGridDay,timeGridWeek,dayGridMonth'
                }}
                selectable={true}
                editable={true}
                height={'100%'}
                titleFormat={{
                    // year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                }}
                slotLabelFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false // Use 24-hour format
                }}
                locale={zhLocale}
                events={events}
            />
        </div>
    );
};

export default Calendar;
